import { css } from "@emotion/core";
import {
  NotoBody,
  NotoLink,
  NotoSmall,
} from "../src/components/Atoms/Typography/Typography.js";
import {
  colors,
  scaleSpacing,
  rem,
  hexRgba,
  mediaquery,
  zIndexes,
} from "./theme";

const animatedBackground = (bg, color, borderRadius, hasBoxShadow) => css`
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${bg};
    border-radius: ${borderRadius}px;
    transition: width 0.3s cubic-bezier(0.25, 0.1, 0.25, 1),
      height 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    ${hasBoxShadow &&
    css`
      box-shadow: inset 0 0 0 1px ${color};
    `};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover::before {
      width: calc(100% + ${scaleSpacing(3)});
      height: calc(100% + ${scaleSpacing(1)});
    }
  }
`;

export const button = ({
  bg,
  color,
  borderRadius = 24,
  animated,
  hasBoxShadow,
  altColor,
}) => css`
  color: ${color};
  position: relative;
  background-color: transparent;
  ${animated
    ? animatedBackground(bg, color, borderRadius, hasBoxShadow)
    : css`
        background-color: ${bg};
        border-radius: ${borderRadius}px;
      `}
  span,
  p,
  div {
    z-index: ${zIndexes.bottom};
    color: ${altColor};
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

// button default styles
export const flexButtonStyle = css`
  ${NotoBody};
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
`;

const primaryButtonStyle = css`
  ${flexButtonStyle}
  height: ${rem(48)};
  padding: 0 ${scaleSpacing(8)};
  &:hover {
    text-decoration-line: none;
  }
`;

const secondaryButtonStyle = css`
  ${flexButtonStyle}
  height: ${rem(48)};
  padding: 0 ${scaleSpacing(8)};
  &:hover {
    text-decoration-line: none;
  }
`;
const menuArrowButtonStyle = css`
  ${flexButtonStyle}
  ${NotoBody}
  width: 100%;
  height: ${rem(48)};
  padding: 0 ${scaleSpacing(8)};
  &:hover {
    text-decoration-line: none;
  }
`;

const tertiaryButtonStyle = css`
  ${NotoLink}
  cursor: pointer;
  text-decoration-line: underline;
`;

const iconButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${scaleSpacing(8)};
  height: ${scaleSpacing(10)};

  ${mediaquery.md(css`
    width: ${scaleSpacing(12)};
    height: ${scaleSpacing(16)};
  `)};
`;

const StyledTag = css`
  ${flexButtonStyle};
  padding: ${scaleSpacing(2)} ${scaleSpacing(4)};
  ${NotoSmall};
`;

// button styles

export const buttonStyle = buttonTheme => {
  switch (buttonTheme) {
    // primary

    case "primary":
      return css`
        ${primaryButtonStyle}
        ${button({
          bg: colors.white,
          color: colors.black,
          borderRadius: 90,
          animated: true,
        })};
      `;

    case "primaryDark":
      return css`
        ${primaryButtonStyle}
        ${button({
          bg: colors.darkgrey2,
          color: colors.white,
          borderRadius: 90,
          animated: true,
        })};
      `;

    case "primaryGreyDark":
      return css`
        ${primaryButtonStyle}
        ${button({
          bg: colors.darkgrey4,
          color: colors.white,
          borderRadius: 90,
          animated: false,
        })};
      `;

    case "primaryGrey":
      return css`
        ${primaryButtonStyle}
        ${button({
          bg: colors.lightgrey,
          color: colors.darkgrey2,
          borderRadius: 90,
          animated: true,
        })};
      `;

    case "primaryRed":
      return css`
        ${primaryButtonStyle}
        ${button({ bg: colors.cinema, color: colors.white, animated: true })};
      `;

    // secondary

    case "secondary":
      return css`
        ${secondaryButtonStyle}
        ${button({
          bg: "transparent",
          color: colors.white,
          borderRadius: 90,
          animated: true,
          hasBoxShadow: true,
        })};
      `;

    case "secondaryDark":
      return css`
        ${secondaryButtonStyle}
        ${button({
          bg: "transparent",
          color: colors.black,
          borderRadius: 90,
          animated: true,
          hasBoxShadow: true,
        })};
      `;

    // tertiary

    case "tertiary":
      return css`
        ${tertiaryButtonStyle}
        ${button({
          bg: "transparent",
          color: colors.white,
          borderRadius: 0,
          animated: false,
        })};
      `;

    case "tertiaryDark":
      return css`
        ${tertiaryButtonStyle}
        ${button({
          bg: "transparent",
          color: colors.black,
          borderRadius: 0,
          animated: false,
        })};
      `;

    // menu

    case "menuArrow":
      return css`
        ${menuArrowButtonStyle}
        ${button({
          bg: "transparent",
          color: colors.black,
          borderRadius: 0,
          animated: true,
        })};
      `;

    // icon

    case "icon":
      return css`
        ${iconButtonStyle}
        ${button({
          bg: colors.white,
          color: colors.black,
          borderRadius: 30,
          animated: true,
        })};

        span {
          display: inline-flex;
        }
      `;

    case "iconAlpha":
      return css`
        ${iconButtonStyle}
        ${button({ bg: hexRgba(colors.white, 0.3, colors.white, 30) })};

        span {
          width: auto;
          display: inline-flex;
        }
      `;

    // tags

    case "tagPrimary":
      return css`
        ${StyledTag}
        ${button({
          bg: colors.lightgrey,
          color: colors.black,
          borderRadius: 90,
        })};
      `;

    case "tagPrimaryDark":
      return css`
        ${StyledTag}
        ${button({ bg: colors.black, color: colors.white, borderRadius: 90 })};
      `;

    case "tagRed":
      return css`
        ${StyledTag}
        ${button({ bg: colors.cinema, color: colors.white, borderRadius: 90 })};
      `;

    case "PrimaryTag_curiosity":
      return css`
        ${StyledTag}
        ${button({
          bg: colors.curiosity,
          color: colors.white,
          borderRadius: 90,
        })};
      `;

    case "PrimaryTag_institut":
      return css`
        ${StyledTag}
        ${button({
          bg: colors.institut,
          color: colors.white,
          borderRadius: 90,
        })};
      `;

    case "PrimaryTag_troiscouleurs":
      return css`
        ${StyledTag}
        ${button({
          bg: colors.troiscouleurs,
          color: colors.white,
          borderRadius: 90,
        })};
      `;

    case "SecondaryTag_curiosity":
      return css`
        ${StyledTag}
        ${button({
          color: colors.curiosity,
          bg: colors.white,
          borderRadius: 90,
        })};
      `;

    case "SecondaryTag_institut":
      return css`
        ${StyledTag}
        ${button({
          color: colors.institut,
          bg: colors.white,
          borderRadius: 90,
        })};
      `;

    case "SecondaryTag_troiscouleurs":
      return css`
        ${StyledTag}
        ${button({
          color: colors.troiscouleurs,
          bg: colors.white,
          borderRadius: 90,
        })};
      `;

    case "tagYellow":
      return css`
        ${StyledTag}
        ${button({ bg: colors.yellow, color: colors.white, borderRadius: 90 })};
      `;

    case "tagDark":
      return css`
        ${StyledTag}
        ${button({
          bg: colors.darkgrey2,
          color: colors.white,
          borderRadius: 90,
        })};
      `;

    case "tagWhite":
      return css`
        ${StyledTag}
        ${button({
          bg: "transparent",
          color: colors.white,
          borderRadius: 0,
        })};
      `;

    default:
      return css`
        ${button({
          bg: "transparent",
          color: colors.black,
          borderRadius: 0,
          animated: true,
        })};
      `;
  }
};

// custom styles

export const splitButtonLabel = css`
  color: ${colors.cinema};
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    display: block;
    width: 1px;
    height: ${rem(10)};
    background-color: ${colors.darkgrey2};
    margin: ${rem(3)} ${scaleSpacing(2)} 0;
  }
`;
